import {record} from '@/api'
const actions={
    async getRecord(context){
        const result =await record();
        console.log(result)
        context.commit('GETRECORD',result)
    }
}
const mutations={
    GETRECORD(state,value){
        state.record=value.data;
    }
}
const state={
    record:[]
}
const getters={

}
export default{
    actions,
    mutations,
    state,
    getters
}