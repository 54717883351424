<template>
    <div>
        测试
        <form action="http://localhost:8888/admin/system/sysRole/save" method="post">
            <input type="text" name="role_name">
            <input type="text" name="role_code">
            <input type="text" name="description">

            <input type="submit" value="提交">
        </form>
    </div>
</template>

<script>
import axios from 'axios'
    export default {
        name:'Test',
        mounted(){
            // axios.get("https://enka.network/api/uid/157955168")
            // .then(response=>{
            //     console.log(response.data)
            // })
            // .catch(error=>{
            //     console.log("失败",error)
            // })
            // axios.delete("admin/system/sysRole/remove/8").then(response=>{
            //     console.log(response.data)
            // })
            axios.get("admin/system/sysRole/2/1")
            .then(response=>{
                console.log(response.data)
            })
            // axios.post("admin/system/sysRole/save",{
            //         roleName:"testPost",
            //         roleCode:"abc"
            // })
            // .then(response=>{
            //     console.log(response.data)
            // })
        }
    }
</script>

<style lang="scss" scoped>

</style>