import {updateData,getRole} from '@/api/index'

const actions={
    async getData(context,value){
        const result=await updateData(value);
        // console.log(result)
        const role = await getRole();
        context.commit('GETDATA',result.data);
        context.commit('GETROLE',role);
        // context.commit('SETROLELIST')
        return result
    },
    // async getRole(context){
    //     const result = await getRole();
    //     // console.log('aaa',result);
    //     context.commit('GETROLE',result);
    // },
    setRoleList(context){
        console.log(context);
        context.commit('SETROLELIST')
    }
}
const mutations={
    GETDATA(state,value){
        state.data=value
    },
    GETROLE(state,value){
        state.roleData=value
    },
    SETROLELIST(state){
        state.roleList=[]
        state.data.avatarInfoList.forEach((data,index)=>{
            data.name=state.roleData[data.avatarId].name
            state.roleList.push(data);
        })
    }
}
const state={
    //获取数据
    data:{},
    //全部角色数据
    roleData:{},
    //角色列表
    // roleList:[]
}
export default{
    actions,
    mutations,
    state
}