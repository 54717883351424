<template>
    <el-main>
        <h1>更新面板</h1>
        <div class="main-input">
            <input  type="text" placeholder="请输入UID" v-model="inputData">
            <button @click="updateUserData"><img src="https://hutaoya.cn/images/right.svg" width="30" height="30"
                    alt=""></button>
        </div>
    </el-main>
</template>

<script>
export default {
    name: 'UserData',
    data() {
        return {
            inputData: ''
        }
    },
    
    methods: {
        async updateUserData() {
            const requestData=await this.$store.dispatch('getData',this.inputData);
            // console.log("---",requestData);
            if(requestData!= '错误'){
                this.$router.push({
                    name: 'data',
                    params:{
                        uid:this.inputData
                    }
                })

            }
        }
    }
}
</script>

<style lang="less" scoped>
.main {
    width: 100%;
    height: 100vh;
    margin-top: 0 !important;
    background: url("https://hutaoya.cn/images/user.jpg");
    background-size: 100%;
    background-position: center top;
    display: flex;
    justify-content: center;
    h1{
        color: #fff;
        margin-bottom: 1rem;
        text-shadow: 0px 2px 1px #000;
    }
    .main-input {
        width: 270px;
        height: 50px;

        border-radius: 8px;
        // border: 1px solid #fff;
        background: rgba(0, 0, 0, .5);
        box-shadow: 0 4px 9px #0006, 0 0 30px #66ccff;
        display: flex;
        overflow: hidden;

        >input {
            width: 100%;
            padding-left: 1rem;
            padding-right: 1rem;
            border: none;
            background: none;
            color: #fff;
            font-size: 16px;
            background: rgba(0, 0, 0, 0.5);
            text-align: center;
        }

        >button {
            width: 50px;
            height: 50px;
            background: none;
            border: none;
            color: #fff;
            font-weight: 700;
            font-size: 24px;
            background: rgba(0, 0, 0, 0.5);
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
@media screen and(max-width: 1400px) {
    .main{
        background-size: auto 100%;
    }
}
</style>