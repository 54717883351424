<template>
    <el-main>
        <div class="group">
            <h1>交流群</h1>
            <ul class="group-inner">
                <li>
                    <div class="group-inner-layout">
                        <h3>交流群①</h3>
                        <p>700979511</p>
                    </div>
                    <button>点击加群</button>
                </li>
                <li>
                    <div class="group-inner-layout">
                        <h3>交流群②</h3>
                        <p>122523813</p>
                    </div>
                    <button>点击加群</button>
                </li>
                <li>
                    <div class="group-inner-layout">
                        <h3>交流群③</h3>
                        <p>462700885</p>
                    </div>
                    <button>点击加群</button>
                </li>

            </ul>
        </div>
    </el-main>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.main {
    display: flex;

    .group {
        width: 1200px;
        padding-left: 1rem;
        padding-right: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #fff;
        border-radius: 5px;

        .group-inner {
            width: 1020px;
            display: flex;
            margin-bottom: 1rem;
            flex-wrap: wrap;

            // justify-content: center;
            >li {
                width: 500px;
                height: 200px;
                border-radius: 5px;
                border: #e82323 1px solid;
                margin-top: 1rem;
                flex: 0 1 auto;
                display: flex;
                justify-content: space-between;
                padding: 2rem;
                align-items: center;


                &:nth-child(2n) {
                    margin-left: 20px;
                }
                button{
                    width: 75px;
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;
                    color: #f4d8a8;
                    background: #2d2f33;
                    border-radius: 4px;
                    cursor: pointer;
                }
                h3{
                    font-size: 20px;
                }
                p{
                    font-size: 16px;
                    margin-top: 1rem;
                    color: #999;
                }
            }
        }
    }
}

h1 {
    width: 150px;
    margin-top: 1rem;
    border-bottom: 2px solid #e82323;

}
</style>