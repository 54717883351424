<template>
    <!-- <div>
        <h1>个人简历</h1>
        <p></p>
        
    </div> -->
    <el-main>
        <div class="main-inner">
            <h1>个人简历</h1>
            <h3>电话:{{ resume.phone }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 网站:{{ resume.website }}</h3>
            <hr>
            <h3>姓名:{{ resume.name }}</h3>
            <h2>教育背景:</h2>
            <div class="main-inner-layout">
                <p>邯郸职业技术学院 | 数字媒体技术 | 专科</p>
            </div>
            <h2>技能专长:</h2>
            <div class="main-inner-layout">
                <p class="" v-for="(s, index) in resume.skill" :key="index">{{ s }}</p>
            </div>
            <h2>证书:</h2>
            <div class="main-inner-layout">
                <p v-for="(c, index) in credential" :key="index">{{ c }}</p>
            </div>
            <h2>获奖情况</h2>
            <div class="main-inner-layout" v-for="(award, i) in award" :key="i">
                <P v-for="(a, i) in award" :key="i">
                    {{ a }}
                </P>
            </div>
            
            <h2>自我评价</h2>
            <div class="main-inner-layout">
                <p>{{ resume.msg }}</p>
            </div>
        </div>
    </el-main>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: "Resume",
    methods: {

    },
    mounted() {
        this.$store.dispatch('getResume')
    },
    computed: {
        ...mapState({
            resume: (state) => state.resume.resumeData,
            credential: (state) => state.resume.resumeData.credential,
            award: (state) => state.resume.resumeData.award

        })
    }
}
</script>

<style lang="less" scoped>
.main {
    display: flex;

    .main-inner {
        width: 1200px;
        background: #fff;
        padding: 1rem;
        border-radius: 8px;
        box-shadow: 0 0;

        >h1 {
            color: #66ccff;
        }

        >h3 {

            text-align: center;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;

        }

        >h2 {
            font-size: 16px;
            height: 30px;
            line-height: 30px;
            // background: #66ccff;
            background: linear-gradient(to right, #66ccff, #ffffff22);
            color: #fff;
            padding-left: 0.5rem;
        }

        p {
            line-height: 25px;
            padding-left: 0.5rem;
        }

        .main-inner-layout {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
}
</style>