<template>
	<div>
		<!-- 轮播图 -->
		<el-carousel trigger="click">
			<el-carousel-item v-for="(item, i) in 3" :key="i">
				<img :src="`https://hutaoya.cn/images/user${item}.jpg`" alt="">
			</el-carousel-item>
		</el-carousel>
		
		<div class="main-mid">
			<h1>胡桃PV</h1>
			<el-row :gutter="20">
				<el-col :lg="6" :x="24" :md="12" v-for="(pv, i) in pv" :key="i">
					<router-link :to="{
						path: '/video',
						query: {
							link: pv.link,
							title: pv.title,
							inner: pv.inner,
						}
					}">
						<div class="grid-content">
							<el-image :src="pv.images"></el-image>
							<h3>{{ pv.title }}</h3>
							<p>{{ pv.inner }}</p>
						</div>
					</router-link>

				</el-col>
			</el-row>
		</div>
		
		<div class="main-mid">
			<h1>原神资讯</h1>
			<el-row :gutter="20">
				<el-col :lg="6" :x="24" :md="12" v-for="(message, i) in message" :key="i">
					<router-link :to="{
						path: '/video',
						query: {
							link: message.link,
							title: message.title,
							inner: message.inner,
						}
					}">
						<div class="grid-content notPadding">
							<el-image :src="message.images"></el-image>
							<h3>{{ message.title }}</h3>
							<p class="notPadding-p">{{ message.inner }}</p>
						</div>
					</router-link>
				</el-col>
			</el-row>
		</div>
		<Tool></Tool>
		<h1>素材</h1>
		<div class="main-bottom">
			<ul>
				<li v-for="(material, i) in materialList" :key="i" :lg="12">
					<p>{{ material.wtitle }}</p>
					<p class="main-bottom-p" v-if="material.wname.length">——{{ material.wname }}</p>
				</li>
			</ul>
		</div> 
		<!-- <Group/> -->
		<BreakTop/>
	</div>
</template>

<script>
import {mapState} from 'vuex'
import { nanoid } from 'nanoid'
import Tool from '@/components/Index/Tool'
import BreakTop from '@/components/BreakTop'

export default ({
	name: 'Index',
	components: {Tool,BreakTop},
	methods: {
		// getMaterial() {
		// 	axios.get("http://39.106.142.226/json/material.json")
		// 		.then(response => {
		// 			console.log('素材请求成功', response.data)
		// 			this.materialList = response.data.data.list;
		// 		})
		// 		.catch(error => {
		// 			console.log("素材请求失败", error)
		// 		})
		// },
		// getMain() {
		// 	axios.get("http://39.106.142.226/json/index/main.json")
		// 		.then(response => {
		// 			console.log("主体内容请求成功", response.data)
		// 			this.pv = response.data.pv
		// 			this.message = response.data.message
		// 		})
		// 		.catch(error => {
		// 			console.log("请求失败了", error)
		// 		})
		// }
	},
	mounted() {
		// this.getMain();
		this.$store.dispatch('getmain');
		this.$store.dispatch('getMainMaterial');
		
		// console.log(this.$store)
		// this.getMaterial();
	},
	computed:{
		...mapState({
			pv:(state)=>{
				return state.home.pv
			},
			message:(state)=>{
				return state.home.message
			},
			materialList:(state)=>{
				return state.home.materialList
			}
		})
	}
})
</script>

<style lang="less" scoped>
:root {
  --box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);

}
.el-carousel {
	overflow: hidden;
	width: 100%;
}

.el-carousel__container {
	height: 350px;
}

.el-carousel__item {
	height: 350px;
	display: flex;
	justify-content: center;

}

h1 {
	width: 150px;
	margin-top: 1rem;
	border-bottom: 2px solid #e82323;

}

.main-mid {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.el-row {
		margin: 0 !important;
		width: 100%;
		max-width: 1200px;

	}


	.el-col {
		border-radius: 4px;
		margin-right: 0;
		margin-top: 1rem;
		cursor: pointer;

		.grid-content {
			border-radius: 4px;
			min-height: 36px;
			padding: 6px;
			background-color: #fff;
			box-shadow: 1px 1px 7px -2px #000;

			.el-image {
				border-radius: 4px;
			}

			h3 {
				text-align: center;
			}

			p {
				width: 100%;
				line-height: 20px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				padding-left: 0.2rem;
				padding-right: 0.2rem;
				margin-top: 0.5rem;
				margin-bottom: 0.8rem;

			}
		}

		.notPadding {
			padding: 0 !important;
		}

		.notPadding-p {
			padding-left: 1rem !important;
			padding-right: 1rem !important;
			margin-bottom: 0 !important;
			padding-bottom: 1rem;
		}
	}




}

.grid-content:hover>h3 {
	color: #e82323;
}

.main-bottom {
	width: 100%;
	display: flex;
	justify-content: center;

	ul {
		margin: 1rem 0 !important;
		width: 100%;
		max-width: 1200px;
		background: #fff;
		display: flex;
		flex-wrap: wrap;
		padding: 0.5rem;

		li {
			width: 50%;
			padding: 0.5rem;
		}

		li:nth-child(2n-1) {
			border-bottom: 2px solid rgb(240, 241, 245);
		}

		li:nth-child(2n) {
			border-left: 2px solid rgb(240, 241, 245);
			border-bottom: 2px solid rgb(240, 241, 245);
		}

		p {
			font-weight: 700;
			font-size: 16px;
		}

		.main-bottom-p {
			font-weight: 600;
			font-size: 14px;
			text-align: right;
			color: #e82323;
		}

	}
}

@media screen and (max-width:768px) {
	.main-bottom {
		padding-left: 1rem;
		padding-right: 1rem;

		ul {

			li {
				width: 100%;
				border: none;

				.main-bottom-p {
					margin-top: 0.3rem;
				}
			}

			li:nth-child(2n-1) {
				border: none;
				border-bottom: 1px solid rgb(240, 241, 245);
			}

			li:nth-child(2n) {
				border: none;
				border-bottom: 1px solid rgb(240, 241, 245);

			}
		}
	}
}
@media screen and (min-width:1441px){
	
	.el-carousel__item>img{
		width: 1920px;
	}
}
</style>