import axios from 'axios'
const request =axios.create({
    // baseURL:"/api",
    setTimeout:5000
})
request.interceptors.request.use((config)=>{
    return config;
})
request.interceptors.response.use((res)=>{
    return res.data;
},(error)=>{
    // return Promise.reject(new Error('faile'))
    return '错误'
})

export default request;