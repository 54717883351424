<template>
	<el-main>
		<div class="main-inner">
			<el-row>
				<el-col>
					<h1>网页建成到现在已经有:{{ time }}</h1>
					<!-- <p>{{ now }}</p> -->
				</el-col>
			</el-row>
			<el-row>
				<el-col class="main-inner-layout" v-for="r in record" :key="r.id" :span="24">
					<h1>{{ r.wtitle }}</h1>
					<h3>
						{{ r.winner }}
					</h3>
					<p>{{ r.wtime }}</p>
				</el-col>

			</el-row>
		</div>
	</el-main>
</template>

<script>
import axios from 'axios'
import { nanoid } from 'nanoid'
import {mapState} from 'vuex'
export default ({
	name: 'Record',
	data() {
		return {
			staticNow : '0',
			now:'0',
			distance:'0',
			timeDay:{
				days:'0',
				hours:'0',
				minutes:'0',
				seconds:'0'
			},
			// record: []
		}
	},
	methods:{
		// getRecord(){
		// 	axios.get("http://39.106.142.226/json/record.json")
		// 	.then(response=>{
		// 		console.log("记录获取成功",response.data)
		// 		this.record=response.data.record;
		// 	})
		// 	.catch(error=>{
		// 		console.log("记录获取失败",error)
		// 	})
		// }
	},
	computed: {
		time(){
			return this.timeDay.days + "天" + this.timeDay.hours + "小时"+ this.timeDay.minutes + "分" + this.timeDay.seconds + "秒"
		},
		...mapState({
			record:(state)=>{
				return state.record.record
			}
		})
	},
	mounted() {
		//
		this.staticNow= new Date("4 20 2021 9:0:0").getTime();
		setInterval(() => {
			this.now = new Date().getTime();
			this.distance=this.now - parseInt(this.staticNow) ;
			this.timeDay.days = Math.floor(this.distance / (1000 * 60 * 60 * 24));
			this.timeDay.hours = Math.floor((this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			this.timeDay.minutes = Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60));
			this.timeDay.seconds = Math.floor((this.distance % (1000 * 60)) / 1000);
		}, 100);
		this.$store.dispatch('getRecord')
		// this.getRecord();
		// axios.get("http://39.106.142.226/json/record.json").catch(
		// 	response => {
		// 			console.log('请求成功了',response.data)
		// 			this.data=response.data;
		// 		},
		// 		error => {
		// 			console.log('请求失败了',error.message)
		// 		}
		// )

	},
	beforeCreate() {
		
	},
})
</script>

<style lang="less" scoped>
.main {
	display: flex;
	justify-content: center;

	.el-col {
		padding: 0.5rem;
		box-shadow: 2px 2px 5px -3px #00000070;
		background: #fff;
	}

}

.main-inner {
	width: 100%;
	max-width: 1200px;

	.main-inner-layout {
		width: 100%;
		// border-top: 2px solid #e82323;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		margin-top: 1rem;
		border-radius: 5px;
		background: #fff;
		box-shadow: 2px 2px 5px -3px #00000070;

		>h3 {
			// width: 100%;
			line-height: 30px;
			text-align: center;
		}

		>p {
			line-height: 20px;
			text-align: right;
			padding-left: 1rem;
			padding-right: 1rem;

		}
	}

}
</style>