import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import VueRoter from 'vue-router'

import Router from './router/index.js'
import store from '@/store'
// import {main,mainMaterial} from '@/store'

Vue.use(VueRoter)
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  render: h => h(App),
  router:Router,
  store
}).$mount('#app')
