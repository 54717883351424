import { character } from '@/api/index'
const actions={
    async getCharacter(context){
        const result=await character();
        console.log(result)
        context.commit('GETCharacter',result);
    }
}
const mutations={
    GETCharacter(state,value){
        state.character=value.role
    }
}
const state={
    character:[]
}
const getters={

}
export default{
    actions,
    mutations,
    state,
    getters
}
