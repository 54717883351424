//当前模块统一管理
import request from "./request";
import axios from 'axios'
//主体接口
//接口
export const main=()=>{
    return request({url:'/json/index/main.json',method:'get'})
}
//素材接口
export const mainMaterial=()=>{
    return request({url:'/admin/material/findlist',method:'get'})
}
//记录接口
export const record=()=>{
    return request({url:'/admin/record/findlist',method:'get'})
}
//故事接口
export const story=()=>{
    return request({url:'/json/story.json',method:'get'})
}
//页脚接口
export const footerOther=()=>{
    return request.get('/json/otherLink.json')
}
//更新面板接口
export const updateData=(uid)=>{
    // return request.get(`/api/uid/${uid}`)
    return axios.get(`/api/uid/${uid}`)
    // request.get(`/api/uid/${uid}`)
    // .then(request=>{
    //     console.log(request.data);
    //     return request.data
    // })
    // .catch(error=>{
    //     return '404'
    // })
}
//图鉴接口
export const character=()=>{
    return request.get('/json/role.json')
}
//角色接口
export const getRole=()=>{
    return request.get('json/talent.json');
}


//简历接口
export const resume=()=>{
    return request.get('json/resume.json');
}