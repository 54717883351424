<template>
    <div class="tool">
        <h1>小工具</h1>
        <el-row>
            <el-col :span="8">
                <router-link to="/character" >
                    <div class="tool-inner">
                        <img src="./images/1.svg" class="tool-inner-pic" alt="">
                        <h1>角色图鉴</h1>
                    </div>
                </router-link>

            </el-col>
            <el-col class="tool-mid" :span="8">
                <router-link to="/userdata">

                    <div class="tool-inner tool-inner-mid">
                        <img src="./images/2.svg" class="tool-inner-pic" alt="">
                        <h1>更新面板</h1>
    
                    </div>
                </router-link>
            </el-col>
            <el-col :span="8">
                <router-link to="/harm" >

                    <div class="tool-inner">
                        <img src="./images/3.svg" class="tool-inner-pic" alt="">
                        <h1>伤害计算</h1>
    
                    </div>
                </router-link>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: 'Tool',
    methods:{
    }
}
</script>

<style lang="less" scoped>
.tool {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;

    // height: 400px;
    >h1 {

        width: 150px;
        margin-top: 1rem;
        border-bottom: 2px solid #e82323;
        text-align: center;
    }
}

.el-row {
    border-radius: 5px;
    width: 100%;
    max-width: 1200px;
    margin: 0 !important;
    display: flex;
    flex-direction: row;

    .el-col {
        width: 100%;
        margin-top: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;

        background: #fff;

        .tool-inner {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            // background: #fff;
            .tool-inner-pic {
                width: 120px;
                height: 120px;
                // background: #000;


            }

            >h1 {
                margin-top: 0.5rem;
            }

            &:hover {
                background: #00000022;
            }
        }

        .tool-inner-mid {
            box-shadow: 1px 0 0 0 #e82323,
                -1px 0 0 0 #e82323,
                0 0 0 0 #fff;
        }
    }

    .tool-mid {

        z-index: 2;
    }


}
@media screen and (max-width:960px) {
    .el-row{
        .el-col{
            .tool-inner{
                .tool-inner-pic{
                    width: 80px ;
                    height: 80px ;
                }
                >h1{
                    font-size: 20px;
                }

            }
        }
    }
}

</style>