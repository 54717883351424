//创建路由
import VueRouter from 'vue-router'
//引入组件

import Record from '../pages/Record'
import Harm from '../pages/Harm'
import Index from '../pages/Index'
import LogIn from '../pages/LogIn'
import Story from '../pages/Story'
import Pic from '../pages/Pic'
import Videos from '../pages/Videos'
import UserData from '../pages/UserData'
import Data from '../pages/Data'
import Test from '@/pages/Test'
import NotData from '@/pages/404'
import Character from '@/pages/Character'
import Resume from '@/pages/Resume'
import Group from '@/pages/Group'
import Bug from '@/pages/bug'
import Runcode from '@/pages/Runcode'



export default new VueRouter({
	// mode: 'history',
	routes: [
		{
			path: '/',
			redirect: "/index"
		},
		{
			path: '/index',
			// redirect: Index
			component: Index,
			meta: {
				FooterShow: true
			}
		},
		{
			//记录
			path: '/record',
			component: Record,
			meta: {
				FooterShow: true
			}
		},
		{
			//伤害计算
			path: '/harm',
			component: Harm,
			meta: {
				FooterShow: false
			}
		},
		{
			//登录
			path: '/login',
			component: LogIn,
			meta: {
				FooterShow: false
			}
		},
		{
			//故事
			path: '/story',
			component: Story,
			meta: {
				FooterShow: false
			}
		},
		{
			//图片
			path: '/pic',
			component: Pic,
			meta: {
				FooterShow: false
			}
		},
		{
			path: '/video',
			component: Videos,
			meta: {
				FooterShow: false
			},
			// props:($route)=>{
			//     return {
			//         keword:$route.params.getSerach,k:$route.query.k
			//     }
			// },
			props: ($route) => {
				return {
					inner: $route.query.inner,
					title: $route.query.title
				}
			}
		},
		//更新面板
		{
			path: '/userdata',
			component: UserData,
		},
		//更新页面
		{
			name: 'data',
			path: '/data/:uid',
			component: Data,
		},
		{
			path: '/test',
			component:Test
		},
		//图鉴
		{
			path:'/character',
			component:Character
		},
		//简历
		{
			path:'/resume',
			component:Resume
		},
		{
			path:'/404',
			component:NotData
		},
		{
			path:'/group',
			component:Group
		},
		{
			path:'/bug',
			component:Bug
		},
		{
			path:'/runcode',
			component:Runcode
		}

	]
})