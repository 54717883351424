import {footerOther} from '@/api/index'
const actions={
    async getFooterOther(context){
        const result =await footerOther();
        context.commit('GETFooterOther',result)
        console.log(result)
    }
}
const mutations={
    GETFooterOther(state,value){
       state.footerOther=value.otherLink
    }
}
const state={
    footerOther:[]
}
const getters={

}
export default{
actions,
mutations,
state,
getters
}