import { main, mainMaterial } from '@/api/index'
const actions = {
    async getmain(context) {
        let result =await main()
        // console.log(result)
        context.commit('GETMAIN', result)
    },
    async getMainMaterial(context){
        let result =await mainMaterial();
        console.log(result)
        context.commit('GETMAINMATERIAL',result);
    }
}

const mutations = {
    GETMAIN(state, value) {
        state.pv = value.pv;
        state.message=value.message
    },
    GETMAINMATERIAL(state,value){
        state.materialList=value.data
    }
}
const state = {
    pv: [],
    message: [],
    materialList: []
}
const getters = {

}
export default {
    actions,
    mutations,
    state,
    getters
}