<template>
  <el-main>
    <iframe
      class="run"
      src="https://tool.lu/coderunner/embed/dQW.html"
      frameborder="0"
      mozallowfullscreen
      webkitallowfullscreen
      allowfullscreen
    ></iframe>
  </el-main>
</template>

<script>
export default {
  name: "Runcode",
};
</script>

<style lang="less" scoped>
.main{
    display: flex;
}
.run {
  width: 100%;
  height: 550px;
}
@media screen and (max-width: 1920px) {
  .run {
    max-width: 1200px;
    height: 550px;
  }
}
@media screen and (max-width: 768px) {
    .main{
        padding: 0;
    }
    .run{
        height: calc(100vh - 70px);
    }
}
</style>