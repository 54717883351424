<template>
	<el-main>
		<div class="main-inner">
			<h1>伤害计算3.0</h1>
			<p>攻击力</p>
			<el-row :gutter="18">
				<el-col :span="12">
					<input type="text" v-model="base.basicsAttack" placeholder="基础攻击力">
				</el-col>
				<el-col :span="12">
					<input type="text" v-model="base.percentageAttack" placeholder="百分比攻击">
				</el-col>
			</el-row>

			<p>技能倍率(百分比)</p>

			<el-row>
				<el-col :span="24">
					<input type="text" v-model="base.SkillDMG" placeholder="技能倍率">
				</el-col>
			</el-row>

			<p>伤害加成</p>

			<el-row :gutter="18">
				<el-col :span="24">
					<input type="text" v-model="base.totalDamageBonus" placeholder="伤害加成">
				</el-col>
			</el-row>

			<p>暴击爆伤</p>
			<el-row :gutter="18">
				<el-col :span="12">
					<input type="text" placeholder="暴击">
				</el-col>
				<el-col :span="12">
					<input type="text" v-model="base.critDMG" placeholder="暴击伤害">
				</el-col>
			</el-row>
			<p>元素反应</p>
			<el-row :gutter="18">
				<el-col :span="7">
					<select v-model="base.reaction">
						<option value="0">不反应</option>
						<option value="1.5">火蒸发</option>
						<option value="2.0">火融化</option>
						<option value="2.0">水蒸发</option>
						<option value="1.5">冰融化</option>
					</select>
				</el-col>
				<el-col :span="10">
					<input type="text" v-model="base.ElementalMastery" placeholder="元素精通">
				</el-col>
				<el-col :span="7">
					<select v-model="base.addElemental">
						<option value="0">无加成</option>
						<option value="0.15">15%</option>
						<option value="0.30">30%</option>
					</select>
				</el-col>
			</el-row>
			<p>怪物防御</p>
			<el-row :gutter="18">
				<el-col :span="12">
					<input type="text" v-model="base.character" placeholder="角色等级">
				</el-col>
				<el-col :span="12">
					<input type="text" v-model="base.beings" placeholder="怪物等级">
				</el-col>
			</el-row>
			<p>怪物抗性</p>
			<el-row>
				<el-col :span="24">
					<input type="text" v-model="base.beingsRES" placeholder="怪物抗性">
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<button @click="countHarm">计算</button>
				</el-col>
			</el-row>
			<el-row>
				<el-col>
					<p class="sum">预计伤害:
						<span>{{ sum }}</span>
					</p>
				</el-col>
			</el-row>
		</div>
	</el-main>
</template>

<script>


export default ({
	name: 'Harm',
	data() {
		return {
			base: {
				//基础攻击
				basicsAttack: '',
				//百分比攻击
				percentageAttack: '',
				//技能倍率
				SkillDMG: '',
				//伤害加成
				totalDamageBonus: '',
				//暴击
				critRate: '',
				//暴击伤害
				critDMG: '',
				//反应
				reaction: 0,
				//元素精通
				ElementalMastery: '',
				//加成
				addElemental: 0,
				//角色等级
				character: '',
				//怪物等级
				beings: '',
				//抗性
				beingsRES: '',
				// //减抗
				// beingsReduceRES:''
			},
			count: {
				//攻击
				totalAttack: 0,
				//倍率
				SkillDMG: 0,
				//伤害加成
				totalDamageBonus: 0,
				//爆伤
				critDMG: 0,
				//反应
				totalReaction: 0,
				//DEF
				DEF: 0,
				//抗性
				beingsRES: 0
			},
			sum: 0,



		}
	},
	methods: {
		countHarm() {
			//总攻击
			this.count.totalAttack = parseInt(this.base.basicsAttack) + parseInt(this.base.percentageAttack)
			//技能倍率
			this.count.SkillDMG = parseFloat(this.base.SkillDMG) / 100
			//伤害加成
			this.count.totalDamageBonus = 1 + parseFloat(this.base.totalDamageBonus) / 100
			//暴击伤害
			this.count.critDMG = 1 + parseFloat(this.base.critDMG) / 100

			//反应
			if (this.base.reaction == 0) {
				this.count.totalReaction = 1
			} else {
				//((1 + (2.78 * 精通) / (精通 + 1400) + 加成系数) * 反应系数)
				const element = parseFloat(this.base.ElementalMastery) * 2.78 / (parseFloat(this.base.ElementalMastery) + 1400)
				this.count.totalReaction = ((1 + element + parseFloat(this.base.addElemental)) * parseFloat(this.base.reaction))
			}

			//防御
			if ((this.base.character == '' || this.base.character == undefined) && (this.base.beings == '' || this.base.beings == undefined)) {
				this.count.DEF = 0.5;
				console.log('1')
			} else if (this.base.beings == '') {
				this.count.DEF = (100.0 + parseInt(this.base.character)) / (100 + parseInt(this.base.character) + 100 + 90);
				console.log('2')
			} else if (this.base.character == '' || this.base.character == undefined) {
				this.count.DEF = (100.0 + 90) / (100 + 90 + 100 + parseInt(this.base.beings));
				console.log('3')
			} else {
				this.count.DEF = (100.0 + parseInt(this.base.character)) / (100 + parseInt(this.base.character) + 100 + parseInt(this.base.beings));
				console.log('4')
			}

			//抗性
			if (this.base.beingsRES == undefined || this.base.beingsRES == '') {
				this.count.beingsRES = 1.0 - 0.1
			} else {
				if (1 - parseInt(this.base.beingsRES) / 100.0 <= 1) {
					this.count.beingsRES = 1.0 - parseInt(this.base.beingsRES) / 100.0
				} else {
					this.count.beingsRES = 1.0 - parseInt(this.base.beingsRES) / (100.0 * 2)
				}

			}
			if (this.base.basicsAttack == "" || this.base.percentageAttack == "" || this.base.SkillDMG == "" || this.base.totalDamageBonus == "" || this.base.critDMG == "") {
				return alert("输入数据有空 请填写必要数据后再进行计算");

			}
			this.sum = this.count.totalAttack * this.count.SkillDMG * this.count.totalDamageBonus * this.count.critDMG * this.count.totalReaction * this.count.DEF * this.count.beingsRES
		}
	}
})
</script>

<style lang="less" scoped>
.main {
	display: flex;
	justify-content: center;

}

.main-inner {
	width: 100%;
	max-width: 1200px;
	background-color: #fff;
	padding: 1rem;

	>p {
		width: 100%;
		height: 25px;
		text-align: left;
		font-size: 18px;
		color: #000;
		line-height: 25px;
		font-weight: 900;
		border-left: 5px solid #e82323;
		padding-left: 0.4rem;
		margin-bottom: 5px;
		overflow: hidden;
	}

	// .main-inner-layout{
	//     width: 100%;
	//     height: 30px;
	//     margin-bottom: 10px;
	//     display: flex;
	// }
	input[type=text] {
		width: 100%;
		height: 30px;
		border: 1px solid #e6e6e6;
		border-radius: 3px;
		font-size: 16px;
		padding-left: 1rem;
		margin-bottom: 0.5rem;
	}

	select {
		width: 100%;
		height: 30px;
		border: 1px solid #e6e6e6;
		border-radius: 3px;
		margin-bottom: 1rem;
	}

	button {
		width: 100%;
		height: 40px;
		background: #e82323;
		border: 0;
		color: #fff;
		font-size: 20px;
		cursor: pointer;
	}

	.sum {
		height: 40px;
		font-weight: 600;
		line-height: 40px;
		font-size: 16px;
		>span{
			color: #e82323;
		}
	}
}
</style>