<template>
	<el-main>
		<div class="main-inner">
			<div class="main-inner-pic">
				<h1>胡桃</h1>
				<img src="https://hutaoya.cn/images/story.png" alt="加载失败">

			</div>
			<el-collapse v-for="story in storyData" :key="story.id">
				<el-collapse-item :title="story.title" name="1">
					<div v-for="(story, i) in story.content" :key="i">{{ story }}</div>
				</el-collapse-item>
			</el-collapse>
		</div>
	</el-main>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
export default ({
	name: 'Story',
	mounted() {
		// this.getData()
		this.$store.dispatch('getStory')
	},
	methods: {
		//获取数据
		// getData(){
		// 	axios.get("https://hutaoya.cn/json/story.json")
		// 	.then(response => {
		// 		// console.log(response.data)
		// 		this.storyJson=response.data.story;
		// 	})
		// 	.catch(
		// 		error => {
		// 			console.log('请求失败了', error)
		// 		}
		// 	)
		// }
	},
	computed: {
		...mapState({
			storyData: (state) => {
				return state.story.storyData
			}
		})
	}
})
</script>

<style lang="less" scoped>
.main {
	display: flex;
	flex-direction: column;
	align-items: center;

	.main-inner {
		width: 100%;
		max-width: 1200px;
		.main-inner-pic{
			width: 100%;
			position: relative;
			top: 0;
			left: 0;
			// height: 200px;
			margin-bottom: 1rem;
			>img{
				width: 100%;
				// position: absolute;
			}
			h1{
				position: absolute;
				margin-top: 1rem;
				margin-left: 0.5rem;
				text-align: left;
			}
		}

	}
}
@media screen and (max-width: 768px){
	.main-inner{
		.main-inner-pic{
			width: 100%;
			height: 200px;
			
			overflow: hidden;
			>img{
				width: auto !important;
				position: absolute;
				right: -2rem;

				height: 200px;
			}
			h1{
				z-index: 2;
			}
			
		}
	}
}
</style>