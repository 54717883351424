<template>
    <el-main>
        <div class="main-inner">
            <div class="main-box">
                <ul class="main-box-nav">
                    <li v-for="(role, index) in roleList" :class="{ active: index == thisIndex }" @click="roleClick(index)"
                        :key="index">
                        <!-- {{ role.name }} -->
                        <img :src="`https://hutaoya.cn/images/ico/${role.name}.png`" alt="">
                    </li>
                </ul>

            </div>
        </div>
        <div class="main-bottom">
            <button @click="updateRole">更新面板</button>
            <div class="mian-bottom-inner" v-for="(role, index) in roleList" :key="index" v-show="index == thisIndex">
                <!-- {{ role.name }} -->
                <img :src="`https://hutaoya.cn/images/role/${role.name}.png`" alt="">
                <h1>{{ role.name }}</h1>
                <p>欢迎:{{ uid }}</p>
                <!-- <h1>签名:{{ playerInfo.signature }}</h1> -->
                <ul class="main-box-inner-propery">
                    <li>
                        <p>生命: </p>
                        <p>{{ parseInt(role.fightPropMap[2000]) }}</p>

                    </li>
                    <li>
                        <p>攻击力:</p>
                        <p>{{ parseInt(role.fightPropMap[2001]) }}</p>

                    </li>
                    <li>
                        <p>防御力:</p>
                        <p> {{ parseInt(role.fightPropMap[2002]) }}</p>

                    </li>
                    <li>
                        <p>元素精通:</p>
                        <p> {{ parseInt(role.fightPropMap[28]) }}</p>

                    </li>
                    <li>

                        <p>暴击:</p>
                        <p>{{ parseFloat(role.fightPropMap[20] * 100).toFixed(1) }}</p>
                    </li>
                    <li>
                        <p>暴击伤害:</p>
                        <p>{{ parseFloat(role.fightPropMap[22] * 100).toFixed(1) }}</p>
                    </li>
                    <li>
                        <p>元素充能:</p>
                        <p> {{ parseFloat(role.fightPropMap[23] * 100).toFixed(1) }}</p>
                    </li>
                    <!-- <li>伤害加成:</li> -->
                </ul>
            </div>
        </div>

    </el-main>
</template>

<script>


import { mapState } from "vuex";
export default {
    name: 'Data',
    data() {
        return {
            //角色列表
            roleList: [],
            thisIndex: 0,
            //圣遗物
            reliquary: []

        }
    },
    computed: {
        ...mapState({
            avatarInfoList: (state) => {
                return state.userdata.data.avatarInfoList
            },
            //信息
            playerInfo: (state) => {
                return state.userdata.data.playerInfo
            },
            uid: (state) => {
                return state.userdata.data.uid
            },
            roleData: (state) => {
                return state.userdata.roleData
            },
            // roleList: (state) => {
            //     return state.userdata.roleList
            // }
        }),
    },
    mounted() {
        // console.log(this.$store.state.userdata.uid)
        // console.log(this.$store.state.userdata.data.uid , this.$route.params.uid)
        // if(this.$store.state.userdata.data.uid != this.$route.params.uid){
        //     const requestData= this.$store.dispatch('getData',this.$route.params.uid)
        //     if(requestData== '错误'){
        //         alert('uid错误 即将回到首页!')
        //         this.$router.push({path:'/home'});
        //     }

        // }

        //获取角色列表
        if (Object.keys(this.$store.state.userdata.data).length == 0) {
            this.$store.dispatch('getData', this.$route.params.uid);
        }
        setTimeout(() => {
            // console.log(this)
            this.$store.state.userdata.data.avatarInfoList.forEach((data, index) => {
                data.name = this.$store.state.userdata.roleData[data.avatarId].name
                this.roleList.push(data);
            })
        }, 200);
        // this.$store.dispatch('getRole');
        // if(this.$store.state.userdata.roleList.length==0){

        //     this.$store.dispatch('setRoleList')
        // }
    },
    methods: {

        roleClick(index) {
            this.thisIndex = index;
        },
        updateRole() {
            this.roleList = [];
            this.$store.dispatch('getData', this.$route.params.uid);
            setTimeout(() => {
                // console.log(this)
                this.$store.state.userdata.data.avatarInfoList.forEach((data, index) => {
                    data.name = this.$store.state.userdata.roleData[data.avatarId].name
                    this.roleList.push(data);
                })
            }, 1500);
        }
    }
}
</script>

<style lang="less" scoped>
.main {
    display: flex;

}

.main-inner {
    width: 100%;
    max-width: 1200px;
    padding: 0.5rem;
    background: #fff;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    border-radius: 5px;


    //角色列表导航
    .main-box-nav {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        // margin-top: 1rem;

        >li {
            width: 50px;
            height: 50px;
            border: 1px solid #e82323;
            border-radius: 50%;
            margin-left: 0.2rem;
            margin-right: 0.2rem;
            overflow: hidden;
            >img{
                width: 50px;
                height: 50px;
            }
        }
    }

    .active {
        background: #e82323;
        box-shadow: 0px 0px 10px #66ccff;
    }
}

//角色列表
.main-bottom {
    width: 100%;
    max-width: 1200px;
    height: 500px;
    background-image: url("https://hutaoya.cn/images/role.jpg");
    background-position: center center;
    margin-top: 1rem;
    overflow: hidden;
    position: relative;
    border-radius: 5px;

    //更新面板
    button {
        width: 200px;
        height: 40px;
        border: none;
        background: #e82323;
        border-radius: 5px;
        font-size: 18px;
        text-align: center;
        color: #fff;
        margin-top: 0.5rem;
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        cursor: pointer;
        z-index: 2;
    }

    .mian-bottom-inner {
        // width: 1200px;
        width: 100%;
        height: 500px;
        // background: #e82323;

        position: relative;

        >h1 {
            text-align: left;
            padding-left: 1rem;
            color: #fff;
            font-weight: 700;
            margin-top: 1rem;
            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7)
        }

        >p {
            text-align: left;
            padding-left: 1rem;
            color: #fff;
            font-size: 18px;
            font-weight: 700;
            margin-top: 0.7rem;
            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7)
        }

        >img {
            width: 800px;
            position: absolute;
            left: 0;
            bottom: 0;

        }

        .main-box-inner-propery {
            width: 300px;
            height: 100%;
            // background: rgba(0, 0, 0, 0.3);
            backdrop-filter: blur(2px);
            position: absolute;
            right: 0;
            top: 0;
            padding: 1rem;

            li {
                // border:1px solid #e82323;
                color: #fff;
                font-size: 18px;
                font-weight: 700;
                padding-top: 0.2rem;
                padding-bottom: 0.2rem;
                padding-left: 1rem;
                padding-right: 1rem;
                display: flex;
                justify-content: space-between;

                >p {
                    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7)
                }

                // box-sizing:border-box;
                &:hover {
                    background: rgba(0, 0, 0, 0.5);
                }
            }

        }
    }
}</style>