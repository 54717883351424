<template>
	<el-footer>
		<div class="footer-inner">
			<h3>其他链接:</h3>
			<ul class="footer-inner-layout">
				<li v-for="(l, i) in footerLink" :key="i"><a :href="l.link">{{ l.title }}</a></li>
			</ul>
			
		</div>
		<h3>其他工具</h3>
			<ul class="footer-inner-layout">
				<li>
					<router-link to="/runcode">在线运行python</router-link>
				</li>
			</ul>
		<hr>
		<p class="footer-hr"><a target='_blank' href='https://beian.miit.gov.cn'>冀ICP备2021008609号-2</a></p>
		<p><a target='_blank' href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=13040402000416'><img
					src='https://hutaoya.cn/images/gongan.png'>冀公网安备 13040402000416号</a></p>
	</el-footer>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex';
export default ({
	name: 'FooterInner',
	data() {
		return {
			// footerLink: {},
		}
	},
	mounted() {
		// this.footerLinkLoad();
		this.$store.dispatch("getFooterOther");
	},
	computed:{
		...mapState({
			footerLink:(state)=>{
				return state.footer.footerOther
			}
		})
	},
	methods: {
		// footerLinkLoad() {
		// 	axios.get("https://hutaoya.cn/json/otherLink.json")
		// 		.then(response => {
		// 			console.log("请求成功了", response.data);
		// 			this.footerLink = response.data.otherLink;
		// 			console.log(this.footerLink)
		// 		})
		// 		.catch(
		// 			error => {
		// 				console.log("请求是失败了", error);
		// 			})
		// }
	},


})  
</script>

<style lang="less" scoped>
.el-footer {
	width: 100%;
	height: 100% !important;
	background: #606266;
	display: flex;
	// justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-top: 1rem;
	padding-bottom: 1rem;

	.footer-inner {
		width: 100%;
		height: 100%;
		max-width: 1200px;
		color: #fff;

		.footer-inner-layout {
			// display: flex;

			li {
				float: left;
				margin-top: 0.5rem;
				a {
					padding-left: 0.5rem;
					padding-right: 0.5rem;
					padding-top: 0.2rem;
					padding-bottom: 0.2rem;
					display: block;
				}
			}

			li:hover {
				background: #e8232333;
			}
		}
	}
	
	hr {
		width: 100%;
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	>p {
		color: #fff;
		font-size: 16px;
		font-weight: 700;
		margin-bottom: 0.5rem;

		img {
			position: relative;
			top: 4px;
		}


	}

	a:link {
		color: #fff;
	}

	a:active {
		color: #fff;
	}

	a:hover {
		color: #fff;
	}

	a:visited {
		color: #fff;
	}

}
</style>