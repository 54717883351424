<template>
    <el-main>
        <div class="main-inner">
            <div class="main-inner-box left">
                <video class="video" controls :src="`https://hutaoya.cn/video/${$route.query.link}.mp4`"></video>
            </div>
            <div class="main-inner-box right">
                <h1>{{ title }}</h1>
                <p>{{ inner }}</p>
            </div>
        </div>
    </el-main>
</template>

<script>
    export default {
        name:'video',
        props:['title','inner']
    }
</script>

<style lang="less" scoped>
.main{
    display: flex;
}
    .main-inner{
        width: 100%;
        max-width: 1200px;
        // height: 200px;
        background: #000;
        display: flex;
        .video{
            width: 100%;
            
        }
        .main-inner-box{
            flex:1 1 auto;
        }
        .left{
            width: 100%;
        }
        .right{
            width: 30%;
            flex: 0 0 auto;
            background: #fff;
            padding: 1rem;
        }
        h1{
            margin-bottom: 1rem;
        }
    }
</style>