<template>
    <el-main>
        <div class="main-inner">
            <img src="./images/notdata.png" alt="">
            <h1>你是不是迷路了</h1>
        </div>
    </el-main>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.main {
    display: flex;
    flex-direction: column;
    .main-inner{
        width: 100%;
        max-width: 1200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background:#fff;
        border-radius: 8px;
        padding: 1rem;
    }
}
</style>