var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-carousel',{attrs:{"trigger":"click"}},_vm._l((3),function(item,i){return _c('el-carousel-item',{key:i},[_c('img',{attrs:{"src":`https://hutaoya.cn/images/user${item}.jpg`,"alt":""}})])}),1),_c('div',{staticClass:"main-mid"},[_c('h1',[_vm._v("胡桃PV")]),_c('el-row',{attrs:{"gutter":20}},_vm._l((_vm.pv),function(pv,i){return _c('el-col',{key:i,attrs:{"lg":6,"x":24,"md":12}},[_c('router-link',{attrs:{"to":{
					path: '/video',
					query: {
						link: pv.link,
						title: pv.title,
						inner: pv.inner,
					}
				}}},[_c('div',{staticClass:"grid-content"},[_c('el-image',{attrs:{"src":pv.images}}),_c('h3',[_vm._v(_vm._s(pv.title))]),_c('p',[_vm._v(_vm._s(pv.inner))])],1)])],1)}),1)],1),_c('div',{staticClass:"main-mid"},[_c('h1',[_vm._v("原神资讯")]),_c('el-row',{attrs:{"gutter":20}},_vm._l((_vm.message),function(message,i){return _c('el-col',{key:i,attrs:{"lg":6,"x":24,"md":12}},[_c('router-link',{attrs:{"to":{
					path: '/video',
					query: {
						link: message.link,
						title: message.title,
						inner: message.inner,
					}
				}}},[_c('div',{staticClass:"grid-content notPadding"},[_c('el-image',{attrs:{"src":message.images}}),_c('h3',[_vm._v(_vm._s(message.title))]),_c('p',{staticClass:"notPadding-p"},[_vm._v(_vm._s(message.inner))])],1)])],1)}),1)],1),_c('Tool'),_c('h1',[_vm._v("素材")]),_c('div',{staticClass:"main-bottom"},[_c('ul',_vm._l((_vm.materialList),function(material,i){return _c('li',{key:i,attrs:{"lg":12}},[_c('p',[_vm._v(_vm._s(material.wtitle))]),(material.wname.length)?_c('p',{staticClass:"main-bottom-p"},[_vm._v("——"+_vm._s(material.wname))]):_vm._e()])}),0)]),_c('BreakTop')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }