import {story} from '@/api/index'
const actions={
    async getStory(context){
        const result =await story();
        context.commit('GETstory',result)
    }
}
const mutations={
    GETstory(state,value){
        state.storyData=value.story
    }
}
const state={
    storyData:[]
}
const getters={
    
}

export default{
    actions,
    mutations,
    state,
    getters
}