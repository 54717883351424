<template>
    <div class="breakTop">
        <div v-show="showScroll" @click="scrollToTop" title="返回顶部" class="breakTop-box">
            <img src="./images/top.svg" width="50" alt="">
        </div>
        <div title="公众号" class="breakTop-box">
            <img src="./images/weixin.svg" alt="">
            <div class="breakTop-box-inner">
                <p>公众号</p>
                <img src="./images/gongzhonghao.jpg" alt="">
            </div>
        </div>
        <div title="微信" class="breakTop-box">
            <img src="./images/xiaoxi.svg" alt="">
            <div class="breakTop-box-inner">
                <p>作者微信</p>
                <img src="./images//weixin.jpg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BreakTop',
    data() {
        return {
            showScroll: false
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        handleScroll() {
            this.showScroll = window.pageYOffset > 500;
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>

<style lang="less" scoped>
.breakTop {
    width: 50px;
    // background: #000;
    position: fixed;
    bottom: 3rem;
    right: 2rem;

    //返回顶部盒子
    .breakTop-box {
        width: 50px;
        height: 50px;
        background: #fff;
        color: #fff;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        margin-top: 0.2rem;
        position: relative;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        >img {
            width: 30px;
            height: 30px;
        }

        //返回顶部二级菜单
        .breakTop-box-inner {
            width: 150px;
            // height: 200px;
            background: #fff;
            position: absolute;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            bottom: -1rem;
            left: -160px;
            display: none;
            padding: 1rem;

            >p {
                text-align: center;
                color: #000;
                font-weight: 600;
                margin-bottom: 0.2rem;

            }

            >img {
                width: 100%;
            }

            &::before {
                content: '';
                border-top: 9px solid transparent;
                border-bottom: 9px solid transparent;
                border-left: 9px solid white;
                position: absolute;
                bottom: 2rem;
                right: -9px;
            }
        }

        &:hover .breakTop-box-inner {
            display: block;
        }
    }
}</style>