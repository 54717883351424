<template>
    <div>
        <h1>bug页面 用来展示遇见过的bug</h1>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>