import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import home from './home'
import record from './record'
import story from './story'
import userdata from './userdata'
import footer from './footer'
import character from './character'
import resume from './resume'
export default new Vuex.Store({
    modules:{
        home,
        record,
        story,
        userdata,
        footer,
        character,
        //简历
        resume
    }
})