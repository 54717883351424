<template>
	<el-container id="app">
		<HeaderInner></HeaderInner>
		<router-view class="main"></router-view>
		<FooterInner v-show="$route.meta.FooterShow"></FooterInner>
	</el-container>
</template>
<script>
import FooterInner from '@/components/Footer'
import HeaderInner from './components/HeaderInner.vue'
// import FooterInner from './components/Footer'
export default ({
	name: 'App',
	components: { HeaderInner, FooterInner },
	methods: {

	}
})
</script>
<style>
* {
	margin: 0;
	padding: 0;
	outline: none;
	list-style: none;
	text-decoration: none;
	box-sizing: border-box;
	user-select: none;
}

body {
	background-color: rgb(240, 241, 245);

}

/* 首页图片移入效果 放在index不管用 */
.grid-content .el-image>img {
	transition: all 0.15s ease-in;
}

.grid-content:hover .el-image>img {
	background: #000;
	transform: scale(1.05);
}



h1 {
	width: 100%;
	/* height: 40px; */
	line-height: 40px;
	text-align: center;
}

#app {
	margin: 0;
	padding: 0;
}

.main {
	margin-top: 60px;
	display: flex;
	flex-direction: column;
	align-items: center
}

.el-container {

	flex-direction: column !important;
}

a:link {
	color: #000;
}

a:active {
	color: #000;
}

a:hover {
	color: #000;
}

a:visited {
	color: #000;
}
.el-collapse-item__header {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		width: 100%;
	}

	.el-collapse-item__content {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}
</style>