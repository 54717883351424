<template>
	
</template>

<script>
	export default({
		name:'Pic'
	})
</script>

<style>
</style>