import {resume} from '@/api/index'
const actions={
    async getResume(context){
        const result =await resume();
        console.log(result)
        context.commit('GETRESUME',result);
    }
}
const mutations={
    GETRESUME(state,value){
        state.resumeData=value.web
    }
}
const state={
    resumeData:{}
}
export default{
    actions,
    mutations,
    state
}