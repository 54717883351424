<template>
    <el-main>
        <div class="character">
            <h1>图鉴列表</h1>
            <input type="text" placeholder="搜索">
            <button>搜索</button>
            <ul class="character-inner">
                <li v-for="(role,index) in character" :key="index">
                    <div class="character-inner-layout">
                        <img :src="`https://hutaoya.cn/images/ico/${role.name}.png`" alt="">
                        <p>{{role.name}}</p>
                        <p>元素:{{role.attributes}}</p>
                        <p>星级:{{ role.rank_type }}</p>
                    </div>
                </li>
                
            </ul>
        </div>
    </el-main>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'character',
    methods: {

    },
    mounted() {
        this.$store.dispatch('getCharacter')
    },
    computed:{
        ...mapState({
            character:(state)=>{
                return state.character.character
            }
        })
    }
}
</script>

<style lang="less" scoped>
.main {
    display: flex;
}

.character {
    width: 100%;
    max-width: 1200px;
    // background: #000;
    >h1{
        margin-bottom: 1rem;
    }
    .character-inner {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        >li {

            width: 20%;
            // height: 100px;
            // background: #e82323;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            margin-top: 1rem;
            flex: 0 1 auto;
            
            .character-inner-layout{
                width: 100%;
                background: #fff;
                display: flex;
                flex-direction: column;
                align-items:center;
                padding: 1rem;
                overflow: hidden;
                border-radius: 5px;
                box-shadow: 1px 1px 10px -4px #000;
                >img{
                    width: 106px;
                    height: 106px;
                }
            }
        }
    }
}
@media screen and (max-width:768px) {
    li{
        width: 50% !important;
    }
}
</style>